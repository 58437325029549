import { styled } from "@mui/material/styles";
import algoliasearch from "algoliasearch/lite";
import type { IndexUiState } from "instantsearch.js";
import React, { ReactElement, ReactNode } from "react";
import {
  InstantSearch as AlgoliaInstantSearch,
  Configure,
  InstantSearchProps,
  SearchBox as InstantSearchSearchBox,
} from "react-instantsearch";

import InsightsMiddleware from "../../util/InsightsMiddleware";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
);

type Props = Partial<Omit<InstantSearchProps, "initialUiState">> & {
  children: ReactNode;
  /**
   * Injects a `uiState` to the `instantsearch` instance. You can use this option
   * to provide an initial state to a widget. Note that the state is only used
   * for the first search. To unconditionally pass additional parameters to the
   * Algolia API, take a look at the `configure` widget.
   */
  initialIndexState?: IndexUiState;
};

const SearchBox = styled(InstantSearchSearchBox)({
  display: "none",
});

const InstantSearch = ({
  children,
  initialIndexState,
  ...rest
}: Props): ReactElement => {
  return (
    <AlgoliaInstantSearch
      initialUiState={{
        [process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME]: initialIndexState,
      }}
      {...rest}
      indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME}
      searchClient={searchClient}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      {process.env.STORYBOOK !== "true" && (
        <>
          <Configure clickAnalytics />
          <InsightsMiddleware />
        </>
      )}
      <SearchBox />
      {children}
    </AlgoliaInstantSearch>
  );
};

export default InstantSearch;
