import { QueryHookOptions, QueryResult, useQuery } from "@apollo/client";

import { gql } from "../../__generated__";
import {
  GetMyCountryQuery,
  GetMyCountryQueryVariables,
} from "../../__generated__/graphql";

export const GET_MY_COUNTRY_QUERY = gql(`
    query GetMyCountry($languageCode: String) {
        myCountry {
            code
            name
            localizedName: name(languageCode: $languageCode)
            locality
            region
            subdivisions {
                code
                name
                localizedName: name(languageCode: $languageCode)
            }
        }
    }
`);

const useMyCountryQuery = (
  options?: QueryHookOptions<GetMyCountryQuery, GetMyCountryQueryVariables>,
): QueryResult<GetMyCountryQuery> => {
  return useQuery(GET_MY_COUNTRY_QUERY, options);
};

export default useMyCountryQuery;
