import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { useEffect } from "react";
import { useInstantSearch } from "react-instantsearch";
import aa from "search-insights";
import { v4 as uuidv4 } from "uuid";

import ClickEventEnum from "../../types/ClickEventEnum";

aa("init", {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
});

// can't send events to algolia without setting usertoken
aa("setUserToken", uuidv4());

const InsightsMiddleware = (): null => {
  const { addMiddlewares } = useInstantSearch();

  useEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: null,
      onEvent: (event) => {
        // work to stop sending default events to algolia and send customize events
        if (event.insightsMethod !== "clickedObjectIDsAfterSearch") return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload: any = event.payload;
        if (payload?.queryID) {
          if (
            Object.values(ClickEventEnum).includes(
              payload?.eventName as unknown as ClickEventEnum,
            )
          ) {
            aa("clickedObjectIDsAfterSearch", {
              index: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME,
              eventName: payload.eventName,
              queryID: payload.queryID,
              objectIDs: payload.objectIDs,
              positions: [payload.position],
            });
          } else {
            aa("convertedObjectIDsAfterSearch", {
              index: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME,
              eventName: payload.eventName,
              objectIDs: payload.objectIDs,
              queryID: payload.queryID,
            });
          }
        }
      },
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
};

export default InsightsMiddleware;
