import { Box, Chip } from "@mui/material";
import { useTranslation } from "next-i18next";
import React, { ReactElement, useEffect, useState } from "react";
import { useRefinementList, useSearchBox } from "react-instantsearch";

type Props = {
  attribute: "topics" | "human_support_types" | "categories";
  single?: boolean;
  max?: number;
  center?: boolean;
  querySearch?: boolean;
};

const ItemSelect = ({
  attribute,
  max = 50,
  center,
  querySearch,
}: Props): ReactElement => {
  const { items, refine, canToggleShowMore, toggleShowMore, isShowingMore } =
    useRefinementList({
      attribute,
      limit: max,
      showMore: true,
      showMoreLimit: max * 3,
      sortBy: ["name:asc"],
    });
  const { t } = useTranslation([
    "common",
    "categories",
    "topics",
    "humanSupportTypes",
  ]);
  const searchBox = useSearchBox();
  const query = searchBox.query;
  const searchRefine = searchBox.refine;
  const [queryArray, setQueryArray] = useState([]);
  const [topics, setTopics] = useState([]);

  const handleSearchQuery = (value: string) => {
    const updateValue = `"${value}"`;
    const copyArray = queryArray.filter((e) => e).map((e) => `"${e}"`);
    if (copyArray.includes(updateValue)) {
      const removeValue = copyArray.filter((val) => val !== updateValue);
      searchRefine(removeValue.join(", "));
    } else {
      const addValue = copyArray.concat(updateValue);
      searchRefine(addValue.join(", "));
    }
  };

  useEffect(() => {
    if (querySearch) {
      if (query.includes(",")) {
        setQueryArray(query.replace(/"/g, "").split(", "));
      } else {
        setQueryArray([query.replace(/"/g, "")]);
      }
    }
  }, [query]);

  useEffect(() => {
    // fix for items, if query doesn't have any result, get empty items array
    if (querySearch) {
      setTopics((prevState) => (items.length ? items : prevState));
    }
  }, [items]);

  const itemsToMap = querySearch ? topics : items;
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: center && "center",
      }}
    >
      {itemsToMap.map((item) => (
        <Chip
          color={
            querySearch ?
              queryArray.includes(item.value) ?
                "secondary"
              : "default"
            : item.isRefined ?
              "secondary"
            : "default"
          }
          key={item.value}
          label={t(item.label, {
            ns:
              attribute == "human_support_types" ? "humanSupportTypes" : (
                attribute
              ),
          })}
          onClick={
            querySearch ?
              (): void => handleSearchQuery(item.value)
            : (): void => refine(item.value)
          }
          data-testid="itemChip"
          data-value={item.value}
          data-category={attribute}
          sx={{
            borderRadius: "6px",
            fontWeight: 400,
            mr: 1,
            mb: 1,
          }}
        />
      ))}
      {canToggleShowMore && !isShowingMore && (
        <Chip
          onClick={(): void => toggleShowMore()}
          label={t("show more")}
          data-testid="moreChips"
          sx={{
            borderRadius: "6px",
            fontWeight: 400,
            mr: 1,
            mb: 1,
          }}
        />
      )}
    </Box>
  );
};

export default ItemSelect;
