/* eslint-disable no-use-before-define */
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { HierarchicalMenuItem } from "instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu";
import { useTranslation } from "next-i18next";
import React, { ReactElement, useEffect, useState } from "react";
import { useHierarchicalMenu } from "react-instantsearch";

import SearchIcon from "../../assets/search-icon.svg";
import { useContent } from "../ContentProvider";

type Props = {
  userCountryName?: string;
  inline?: boolean;
  fromWidget?: boolean;
};

const CountrySelect = ({
  userCountryName,
  inline,
  fromWidget,
}: Props): ReactElement => {
  const { items: totalCountries, refine } = useHierarchicalMenu({
    attributes: ["locations.lvl0", "locations.lvl1"],
    limit: 1000,
  });
  const [countries, setCountries] = useState<HierarchicalMenuItem[]>([]);
  const [items, setItems] = useState<HierarchicalMenuItem[]>([]);
  const [useUserCountry, setUseUserCountry] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { countries: contentCountries, host } = useContent();

  // Use the user/detected country unless the user has selected otherwise. This fixes a bug in the widget
  // where the subdivision dropdown wasn't being shown for the user/detected country.
  const country =
    useUserCountry ?
      items.find(({ label }) => label === userCountryName)
    : (items.find(({ isRefined }) => isRefined) ?? null);
  const subdivision = country?.data?.find(({ isRefined }) => isRefined) ?? null;

  useEffect(() => {
    if (
      userCountryName &&
      items[0]?.value !== userCountryName &&
      countries[0]?.value !== userCountryName
    ) {
      const userCountry = items.find((item) => item.label === userCountryName);
      const selectedCountry = items.find(({ isRefined }) => isRefined) ?? null;
      if (userCountry) {
        const newCountries = [
          userCountry,
          ...items.filter((item) => item !== userCountry),
        ];
        setCountries(newCountries);
        // Only select the user/detected country when we are in the widget context and it is different
        if (fromWidget && userCountry.value != selectedCountry?.value) {
          refine(userCountry.value);
          setUseUserCountry(true);
        }
      }
    }
  }, [userCountryName, items]);

  // Fix for: select topics for query on widget page reduces items/countries and zero items break application
  useEffect(() => {
    setItems((prevState) =>
      totalCountries.length ? totalCountries : prevState,
    );
  }, [totalCountries]);

  return (
    <Box
      sx={{
        display: "grid",
        gap: 1,
        gridTemplateColumns: inline && "repeat(auto-fit, minmax(0, 1fr))",
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: inline && "1fr",
        },
      }}
    >
      <Autocomplete
        sx={{
          width: "auto !important",
          ".MuiAutocomplete-inputRoot": {
            borderRadius: "28px",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
            "& fieldset": {
              border: "0 !important",
            },
          },
          ".MuiAutocomplete-option": {
            "& > span": {
              marginRight: 10,
            },
          },
          "&.Mui-expanded .MuiAutocomplete-inputRoot": {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          },
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "20px",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
              mt: -1.1,
            },
          },
        }}
        popupIcon={
          <ExpandMoreRoundedIcon sx={{ color: "rgba(15, 34, 61, 0.5)" }} />
        }
        aria-label={t("country")}
        style={{ width: 300 }}
        options={countries.length > 0 ? countries : items}
        autoHighlight
        getOptionLabel={({ label }): string => {
          const localizedName = contentCountries?.find(
            (country) => country.name === label,
          )?.localizedName;
          return localizedName ?? label;
        }}
        isOptionEqualToValue={(option, value): boolean =>
          option.value === value.value
        }
        blurOnSelect="touch"
        openOnFocus
        value={country}
        onChange={(_e, country): void => {
          // Clear the flag to use the user/detected country as the user has overridden it.
          userCountryName && setUseUserCountry(false);
          // Note: As this is the parent level, a simple clear is all that is required to reset the search filter.
          if (country == null) {
            refine("");
          } else if (typeof country === "string") {
            refine(country);
          } else {
            refine(country?.value);
          }
        }}
        data-testid="countryAutocomplete"
        renderInput={(params): ReactElement => (
          <TextField
            {...params}
            placeholder={t("Start typing your country...")}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    pl: 1.5,
                    "& svg": {
                      fill: (theme) =>
                        host?.useDefaultLinkStyles ?
                          theme.palette.text.primary
                        : theme.palette.primary.main,
                      width: 25,
                      height: 25,
                    },
                  }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
              "data-testid": "countryInput",
              "aria-label": t("Start typing your country..."),
            }}
          />
        )}
      />
      {country?.data != null && country.data.length > 0 && (
        <Autocomplete
          sx={{
            width: "auto !important",
            ".MuiAutocomplete-inputRoot": {
              borderRadius: "28px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
              "& fieldset": {
                border: "0 !important",
              },
            },
            ".MuiAutocomplete-option": {
              "& > span": {
                marginRight: 10,
              },
            },
            "&.Mui-expanded .MuiAutocomplete-inputRoot": {
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            },
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "20px",
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                mt: -1.1,
              },
            },
          }}
          popupIcon={<ExpandMoreRoundedIcon sx={{ color: "text.secondary" }} />}
          aria-label={t("subdivision")}
          options={country.data}
          getOptionLabel={({ label }): string => {
            const contentCountry = contentCountries?.find(
              (contentCountry) => contentCountry.name === country.label,
            );
            const localizedName = contentCountry?.subdivisions?.find(
              (subdivision) => subdivision.name === label,
            )?.localizedName;
            return localizedName ?? label;
          }}
          isOptionEqualToValue={(option, value): boolean =>
            option.value === value.value
          }
          blurOnSelect="touch"
          openOnFocus
          value={subdivision}
          data-testid="subdivisionAutocomplete"
          onChange={(_e, newSubdivision): void => {
            // Note: At the second level Algolia requires a toggle behaviour to remove this refinement
            // i.e replay the previous refinement to remove it
            if (newSubdivision == null) {
              if (typeof subdivision === "string") {
                refine(subdivision);
              } else {
                refine(subdivision?.value);
              }
            } else if (typeof newSubdivision === "string") {
              refine(newSubdivision);
            } else {
              refine(newSubdivision?.value);
            }
          }}
          renderInput={(params): ReactElement => (
            <TextField
              {...params}
              placeholder={t("Refine by location (optional)")}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                "data-testid": "subdivisionInput",
                "aria-label": t("Refine by location"),
              }}
            />
          )}
        />
      )}
    </Box>
  );
};

export default CountrySelect;
